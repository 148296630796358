import './styles.scss';

const iframe = document.querySelector<HTMLElement>('#personio-iframe');

if (iframe) {
  window.addEventListener(
    'message',
    (e) => {
      const eventName = e.data[0];
      const data = e.data[1];
      switch (eventName) {
        case 'setHeight':
          iframe.style.height = data + 'px';
          break;
      }
    },
    false
  );
}
